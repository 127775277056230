import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
// import Debug from 'debug'

import { media } from '../styles/utils'

import { Video } from './Video'
// import { BackgroundImage } from './BackgroundImage'

// const debug = Debug('site:hero')

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.sizes.slidePadding}px;
  ${media.tablet`
    padding: ${(props) => props.theme.sizes.slidePaddingMobile}px;
  `}
`

export const HeroSection = ({ isPlaying, children }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <div className="section">
            <Container>
              <Video
                videoId={data.contentfulHeroVideo.videoId}
                isPlaying={isPlaying}
              />
              {children}
            </Container>
          </div>
        )
      }}
    />
  )
}

const query = graphql`
  query HeroVideoQuery {
    contentfulHeroVideo {
      videoId
      fallbackImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
