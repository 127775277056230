import React, { useEffect, useRef, useState } from 'react'
import Player from '@vimeo/player'
import styled from 'styled-components'

import { removePreloader } from '../lib/removePreloader'

import Debug from 'debug'

const debug = Debug('site:hero-video')

// const Container = styled.div`
//   /* Make video to at least 100% wide and tall */
//   min-width: 100%;
//   min-height: 100%;

//   /* Setting width & height to auto prevents the browser from stretching or squishing the video */
//   width: auto;
//   height: auto;

//   /* Center the video */
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%,-50%);

//   z-index: 2;
//   background: white;
// `

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  background: white;
`

const Iframe = styled.iframe`
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface Props {
  videoId: string
  isPlaying: boolean
}
export const Video: React.FC<Props> = ({ videoId, isPlaying }) => {
  const frame = useRef()
  const [player, setPlayer] = useState<Player>(null)

  useEffect(() => {
    // Initialise player
    const player = new Player(frame.current)

    // Mount listeners
    player.on('loaded', handleVideoLoaded, false)
    player.on('play', handleVideoPlaying, false)

    setPlayer(player)

    return () => {
      // Unmount listeners
      player.off('loaded', handleVideoLoaded, false)
      player.off('play', handleVideoPlaying, false)
    }
  }, [])

  // Play and pause video according to prop changes
  useEffect(() => {
    if (player !== null) {
      if (isPlaying) {
        player.play()
      } else {
        player.pause().then(() => debug('paused'))
      }
    }
  }, [isPlaying])

  const handleVideoLoaded = () => {
    debug('loaded')
    // TODO: iOS Safari not hearing play event, temporarily using loaded
    removePreloader()
  }

  const handleVideoPlaying = () => {
    debug('playing')
  }

  return (
    <Wrapper id={`video-container`}>
      <Iframe
        id="hero-video"
        ref={frame}
        title="vimeo"
        src={`https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&muted=1&background=1`}
        frameBorder="0"
        controls="0"
        allowFullScreen
        muted
      />
    </Wrapper>
  )
}
