import React, { useContext, useState } from 'react'
import Debug from 'debug'
import { navigate, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import { Mousewheel } from 'swiper'
import 'swiper/css'

// import theme from '../styles/theme'
import config from '../utils/siteConfig'

import SEO from '../components/SEO'
import { HeroSection } from '../components/HeroSection'
import { ProjectsCarousel } from '../components/ProjectsCarousel'

import { NavigationArrows } from '../components/NavigationArrows'
import { Arrow } from '../components/NavigationArrows/Arrow'
import SlideIndicator from '../components/SlideIndicator'
import { ContentfulNode, Project } from '../lib/types'
import { ScrollContext } from '../contexts/ScrollContext'
import { animationConfig } from '../utils/animationConfig'

const debug = Debug('site:home-page')

interface State {
  projects: Project[]
  activeSlug: string | undefined
  activeIndex: number
  discoverArrowVisible: boolean
  downArrowVisible: boolean
  horizontalArrowsVisible: boolean
}

interface Props {
  data: {
    allContentfulProject: {
      edges: ContentfulNode[]
    }
  }
}

const HomePage: React.FC<Props> = ({ data }) => {
  const [state, setState] = useState<State>({
    // Data
    projects: data.allContentfulProject.edges.map((edge) => edge.node),
    // Carousel props
    activeSlug: data.allContentfulProject.edges[0].node.slug,
    activeIndex: 0,
    // Navigation arrows
    discoverArrowVisible: true,
    downArrowVisible: false,
    horizontalArrowsVisible: false
  })

  const [playVideo, setPlayVideo] = useState(true)
  const [swiper, setSwiper] = useState<SwiperClass>(null)
  const [carouselSwiper, setCarouselSwiper] = useState<SwiperClass>(null)
  const { setShouldScroll } = useContext(ScrollContext)

  /**
   * Vertical swiper
   */
  const handleSlideChange = (swiper: SwiperClass) => {
    const isHero = swiper.realIndex === 0

    // Play and pause the video when we are on the hero slide
    if (isHero) {
      setPlayVideo(true)
    } else {
      setPlayVideo(false)
    }
  }

  const handleVerticalChangeStart = (swiper: SwiperClass) => {
    const destinationIndex = swiper.realIndex
    if (destinationIndex !== 0) {
      setState({
        ...state,
        // Hide discover arrow whilst leaving hero
        discoverArrowVisible: false
      })
    }

    if (destinationIndex === 0) {
      setState({
        ...state,
        downArrowVisible: false,
        horizontalArrowsVisible: false
      })
    }
  }

  const handleVerticalChangeEnd = (swiper: SwiperClass) => {
    const destinationIndex = swiper.realIndex
    if (destinationIndex === 0) {
      setState({
        ...state,
        // Show discover arrow after entering hero
        discoverArrowVisible: true
      })
    }

    if (destinationIndex !== 0) {
      setState({
        ...state,
        downArrowVisible: true,
        horizontalArrowsVisible: true
      })
    }
  }

  /**
   * Carousel swiper
   */
  const handleCarouselChange = (swiper: SwiperClass) => {
    const destinationIndex = swiper.realIndex
    const projects = data.allContentfulProject.edges
    const activeSlug = projects[destinationIndex].node.slug
    debug('go to slide', destinationIndex, activeSlug)

    setState({
      ...state,
      activeSlug,
      activeIndex: destinationIndex,
      horizontalArrowsVisible: false
    })
  }

  const handleCarouselChangeStart = (swiper: SwiperClass) => {}

  const handleCarouselChangeEnd = () => {
    if (!swiper) return
    if (swiper.realIndex === 0) return
    // show horizontal arrows after slide has transitioned
    setState({
      ...state,
      horizontalArrowsVisible: true
    })
  }

  const handleDiscover = (): void => {
    if (!swiper) return
    swiper.slideNext()
  }

  const handleNavigateAndScrollProject = (slug: string) => {
    setShouldScroll(true)
    navigate(`/${slug}`)
  }

  // debug('projects', data.allContentfulProject.edges)

  return (
    <>
      <SEO />

      <Helmet>
        <title>{config.siteTitle}</title>
      </Helmet>

      <NavigationArrows
        horizontalArrowsVisible={state.horizontalArrowsVisible}
        downArrowVisible={state.downArrowVisible}
        handleNavigateProject={() =>
          handleNavigateAndScrollProject(state.activeSlug)
        }
        handlePrevious={() => carouselSwiper.slidePrev()}
        handleNext={() => carouselSwiper.slideNext()}
      />

      <div style={{ width: '100vw', height: '100vh' }}>
        <Swiper
          spaceBetween={0}
          speed={animationConfig.slideDuration}
          direction={'vertical'}
          slidesPerView={1}
          mousewheel={true}
          modules={[Mousewheel]}
          onSwiper={setSwiper}
          onSlideChange={handleSlideChange}
          onSlideChangeTransitionStart={handleVerticalChangeStart}
          onSlideChangeTransitionEnd={handleVerticalChangeEnd}
        >
          <SwiperSlide>
            <HeroSection isPlaying={playVideo}>
              <Arrow
                text="Discover Projects"
                position={'bottom'}
                absolute
                show={state.discoverArrowVisible}
                onClick={handleDiscover}
              />
            </HeroSection>
          </SwiperSlide>
          <SwiperSlide>
            <ProjectsCarousel
              projects={state.projects}
              initialIndex={0}
              onSwiper={setCarouselSwiper}
              onSlideChange={handleCarouselChange}
              onSlideChangeTransitionStart={handleCarouselChangeStart}
              onSlideChangeTransitionEnd={handleCarouselChangeEnd}
              handleNavigateProject={handleNavigateAndScrollProject}
            />
            <SlideIndicator
              index={state.activeIndex}
              numberOfProjects={state.projects.length}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}

export default HomePage

export const query = graphql`
  query {
    allContentfulProject(
      sort: { fields: position, order: ASC }
      filter: { active: { eq: true } }
    ) {
      edges {
        node {
          name
          slug
          active
          tagline
          textColor
          position
          heroBackground {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          heroBackgroundMobile {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
